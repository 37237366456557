import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Body, ContentContainer, Toolbar, Subheading } from 're-cy-cle';
import { IconButton } from 'spider/semantic-ui/Button';
import { Table } from 'semantic-ui-react';
import ConfirmModal from 'ConfirmModal';
import SaveButton from 'component/form/SaveButton';
import { DATETIME_FORMAT, DATETIME_MINI_FORMAT, SERVER_DATETIME_FORMAT } from 'helpers';
import styled from 'styled-components';
import { Activity, TYPE_CHANGE } from 'store/Activity';
import { Allocation } from 'store/Allocation';
import { ActivityStore } from 'store/Activity';
import { get } from 'lodash';
import { api } from 'store/Base';
import FormNumberInput from 'spider/Form/NumberInput';
import { PageLoader } from 'component/Loader';
import { TruckPosition } from 'store/TruckPosition';
import { parseBackendErrorMessages, SCREEN_WIDTH_PX } from 'helpers';
import { CancelButton } from 'spider/Button';

function disallowChangingStartKm(previousActivity, anActivity, firstTruckActivity) {
    return !!anActivity.finalizedAt ||
        firstTruckActivity.id !== anActivity.id;
}

const RightButton = styled(SaveButton)`
    margin-left: auto;
`;

const ClickedRoute = styled.div`
    margin-bottom: 16px;
    text-align: center;
`;

const StyledToolbar = styled(Toolbar)`
    justify-content: center;
`;

const StyledHeading = styled(Subheading)`
    text-align: center;
    margin: 0px;
`;

export const StyledTableRow = styled(Table.Row)`
    ${props=>props.emphasize && `
        background-color: #DDF1EE;
        `}
`;

const Container = styled.div`
    position: relative;
    width: 100%;
`;

const StyledContentContainer = styled(ContentContainer)`
    margin: auto;
    height: auto;
    overflow-y: auto !important;
    overflow-x: hidden;
`

const Content = styled.main`
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: baseline;
`

class ModalHeading extends Component {
    static propTypes = {
        children: PropTypes.node,
    }

    render() {
        return (
            <StyledToolbar>
                <StyledHeading>{this.props.children}</StyledHeading>
            </StyledToolbar>
        );
    }
}

function formatDatetime(datetime, format = DATETIME_FORMAT) {
    return datetime ? datetime.format(format) : '-';
}


@observer
export default class ModalChangeKm extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        viewStore: PropTypes.object.isRequired,
        allocation: PropTypes.instanceOf(Allocation).isRequired,
        activity: PropTypes.instanceOf(Activity).isRequired,
        truck: PropTypes.object.isRequired,
        truckPosition: PropTypes.instanceOf(TruckPosition),
        onClick: PropTypes.func.isRequired,
    };

    @observable km = '';
    @observable errors = [];
    @observable firstTruckActivity = new Activity();
    @observable activityStore = new ActivityStore({
        params: {
            '.assignment.truck': this.props.truck.id,
            'include_invoiced': 'true',
            'order_by': 'finished_datetime',
        },
        limit: null,
        relations: ['previousActivity', 'previousStops', 'assignment.truck', 'location', 'allocation.contract.customer'],
    });

    UNSAFE_componentWillMount() {
        const { activity } = this.props;
        const previousStops = activity.previousStops.filter(stop => !stop.deleted);

        // Start looking for activities from the first stop before this
        // activity. If no stop is present, limit to about 2 weeks to avoid
        // fetching too much data.
        const previousStopDatetime = previousStops.length > 0
            ? previousStops[0].orderedArrivalDatetimeFrom.format(SERVER_DATETIME_FORMAT)
            : activity.orderedArrivalDatetimeFrom.clone().subtract(2, 'weeks').format(SERVER_DATETIME_FORMAT);

        this.activityStore.params['.ordered_arrival_datetime_from:gte'] = previousStopDatetime;
        this.activityStore.fetch();

        const { type } = this.props;
        this.km = this.props.truck[`${type}Km`];
    }

    componentDidMount() {
        this.props.truck.fetchFirstActivity().then(firstActivity => {
            if (firstActivity) {
                this.firstTruckActivity = firstActivity;
            }
        });
    }

    handleClose = () => {
        this.props.viewStore.setModal(null);
    };

    handleSave = () => {
        const { viewStore } = this.props;
        const data = [];
        this.errors = [];

        this.activityStore.forEach((activity, i) => {
            const previousActivity = this.activityStore.findPreviousActivityByIndex(activity);
            if (activity.hasUserChanges) {
                if (!disallowChangingStartKm(previousActivity, activity, this.firstTruckActivity)) {
                    data.push({
                        id: activity.id,
                        start_km: parseInt(activity.startKm),
                        end_km: parseInt(activity.endKm),
                    });
                } else {
                    data.push({
                        id: activity.id,
                        end_km: parseInt(activity.endKm),
                    });
                }
            }
        });

        return api.post('activity/tweak_end_km/', data)
            .then(() => this.props.viewStore.showSaveNotification())
            .then(() => this.props.activity.endKm = this.activityStore.get(this.props.activity.id).endKm)
            .then(() => this.props.activity.startKm = this.activityStore.get(this.props.activity.id).startKm)
            .then(this.handleClose)
            .then(() => {
                this.props.onClick()
            })
            .catch(response => {
                const messages = parseBackendErrorMessages(response.response.data.errors);

                messages.forEach(message => {
                    viewStore.showNotification({
                        key: message,
                        type: 'error',
                        dismissAfter: 4000,
                        message,
                    });
                });
            });
    };

    parseBackendErrors = e => {
        // The server response is about the next activity error.
        const nextActivityErrors = Object.values(
            get(e, 'response.data.errors.activity', {})
        );

        if (nextActivityErrors) {
            this.errors = Object.values(nextActivityErrors[0])[0];
        }
    };

    updateKm = (name, value) => {
        this.km = value;
    };

    setStartKm = (activity, value) => {
        activity.setInput('startKm', value);
    }

    setEndKm = (activity, value) => {
        activity.setInput('endKm', value);

        const nextActivity = this.activityStore.findNextActivityByIndex(activity);

        // When the next activity is driven by another truck, don't change the
        // startKm (even though it's only for displaying).
        if (nextActivity && activity.assignment.truck.id === nextActivity.assignment.truck.id) {
            nextActivity.startKm = value;
        }
    }

    render() {
        const { activity, truckPosition } = this.props;

        return (
            <ConfirmModal
                onClose={this.handleClose}
                height="800px"
                width={SCREEN_WIDTH_PX}
            >
                <Body data-test-change-km-modal>
                    <ModalHeading>Change km</ModalHeading>
                    <StyledContentContainer>
                        <Content>
                            {truckPosition && <ClickedRoute>{t('activity.modalChangeKm.clickedRoute', { odometer: truckPosition.odometer })}</ClickedRoute>}
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{t('planning.customer.headings.customer')}</Table.HeaderCell>
                                        <Table.HeaderCell />
                                        <Table.HeaderCell>{t('activity.field.id.label')}</Table.HeaderCell>
                                        <Table.HeaderCell>{t('activity.field.kind.label')}</Table.HeaderCell>
                                        <Table.HeaderCell>{t('activity.field.type.label')}</Table.HeaderCell>
                                        <Table.HeaderCell>{t('activity.field.location.label')}</Table.HeaderCell>
                                        <Table.HeaderCell>{t('activity.field.finishedDatetime.label')}</Table.HeaderCell>
                                        <Table.HeaderCell>{t('truck.field.licensePlate.label')}</Table.HeaderCell>
                                        <Table.HeaderCell>{t('activity.field.startKm.label')}</Table.HeaderCell>
                                        <Table.HeaderCell>{t('activity.field.endKm.label')}</Table.HeaderCell>
                                        <Table.HeaderCell />
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.activityStore.map(this.renderActivity)}
                                </Table.Body>
                            </Table>
                            <PageLoader show={this.activityStore.isLoading} />
                            {this.errors.map((error, i) => (
                                <p key={i}>{error.message}</p>
                            ))}
                        </Content>
                    </StyledContentContainer>
                    <Toolbar>
                        <CancelButton onClick={this.handleClose} />
                        <RightButton
                            loading={activity.isLoading}
                            onClick={this.handleSave}
                        >
                            {t('form.saveButton')}
                        </RightButton>
                    </Toolbar>
                </Body>
            </ConfirmModal>
        );
    }

    renderActivity = (anActivity, i) => {
        const { activity, truckPosition } = this.props;

        if (anActivity.deleted) {
            return null;
        }

        if (anActivity.startKm === null || anActivity.endKm === null) {
            return null;
        }

        let start = activity.orderedArrivalDatetimeFrom.format('X');

        // {previous-activity-deleted}
        if (activity.previousStops.filter(stop => !stop.deleted).length > 0) {
            start = activity.previousStops.filter(stop => !stop.deleted)[0].orderedArrivalDatetimeFrom.format('X');
        }

        if (anActivity.orderedArrivalDatetimeFrom.format('X') < start) {
            return null;
        }
        const location = anActivity.location ? anActivity.location.city : '';
        const previousActivity = this.activityStore.findPreviousActivityByIndex(anActivity);
        // // Save this code in case of change conceptions about edit end KM
        // const hasUpcomingFinalized = some(this.activityStore.models, ac => {
        //     if (ac.orderedArrivalDatetimeFrom.format('X') >= anActivity.orderedArrivalDatetimeFrom.format('X')) {
        //         return !!ac.finalizedAt;
        //     }

        //     return false;
        // });
        const nextActivity = (!!this.activityStore.findNextActivityByIndex(anActivity)) ? this.activityStore.findNextActivityByIndex(anActivity) : null

        const disabled = disallowChangingStartKm(previousActivity, anActivity, this.firstTruckActivity);

        return (
            <StyledTableRow data-test-activity-id={anActivity.id} key={anActivity.id} emphasize={anActivity.id === activity.id}>
                <Table.Cell>
                    <div>{anActivity.allocation.contract.customer.name}</div>
                    <div>{anActivity.allocation.contract.name}</div>
                </Table.Cell>
                <Table.Cell>
                    <div><strong>{anActivity.allocation.customerCode}</strong></div>
                </Table.Cell>
                <Table.Cell>{anActivity.id}</Table.Cell>
                <Table.Cell>{anActivity.kind}</Table.Cell>
                <Table.Cell>{anActivity.type}</Table.Cell>
                <Table.Cell>{location}</Table.Cell>
                <Table.Cell>{formatDatetime(anActivity.finishedDatetime, DATETIME_MINI_FORMAT)}</Table.Cell>
                <Table.Cell>{anActivity.assignment.truck.licensePlate}</Table.Cell>
                <Table.Cell>
                    <FormNumberInput
                        disabled={disabled}
                        model={anActivity}
                        name="startKm"
                        onChange={(name, value) => this.setStartKm(anActivity, value)}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Container
                        title={`${t('activity.field.finalized.label')}: ${anActivity.finalizedAt ? formatDatetime(anActivity.finalizedAt) : '-'}\n${t('activity.field.startKm.label')}: ${anActivity.startKm}\n${t('activity.field.endKm.label')}: ${anActivity.endKm}\n${t('activity.field.currentOdometer.label')}: ${anActivity.assignment.truck.currentOdometer}`}
                    >
                        <FormNumberInput
                            disabled={nextActivity ? (!!anActivity.invoiced || !!nextActivity.invoiced) : !!anActivity.invoiced}
                            model={anActivity}
                            name="endKm"
                            hasError={anActivity.endKm < anActivity.startKm}
                            onChange={(name, value) => this.setEndKm(anActivity, value)}
                            helpText={truckPosition && <IconButton fitted name="crosshairs" onClick={() => this.setEndKm(anActivity, truckPosition.odometer)} />}
                        />
                    </Container>
                </Table.Cell>
            </StyledTableRow>
        );
    }
}
